* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}
.react-pdf__Document {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.react-pdf__Page {
  width: 340px;
}
